<template>
  <div>
    <top-layer></top-layer>
    <div class="main">
      <div class="go-home" @click="otherPage()">
        <i
          style="position: absolute; font-size: 30px; left: 0px"
          class="el-icon-back"
        ></i>
        返回首页
      </div>
      <div class="main-title">货主认证</div>
      <div class="main-info">
        填写本人信息
        <p>（本人信息默认为企业负责联系人信息）</p>
      </div>
      <div class="main-card">
        <div class="card-info">
          <p>姓名：</p>
          <el-input v-model="name" placeholder="请输入内容"></el-input>
        </div>
        <div class="card-info">
          <p>身份证号：</p>
          <el-input v-model="name" placeholder="请输入内容"></el-input>
        </div>
        <div class="card-info info-image">
          <p>身份证</p>
          <div style="width: 90%; display: flex">
            <div class="card-image">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div class="card-image">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <p style="width: 80%; display: flex; align-items: end;color:gray">
              温馨提示：根据《中华人民共和国电子商务法》的监管要求，我们需要你提供真实的身份证信息进行司机身份的认证
            </p>
          </div>
        </div>
      </div>
      <div class="main-info">企业证件信息</div>
      <div style="display: flex">
        <p>营业执照</p>
        <div class="card-image">
          <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <p style="margin-right: 20px">经营许可证</p>
        <div class="card-image">
          <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
      <div class="main-info">常发货源地</div>
      <div class="main-address">
          <div class="address-item" v-for="item in 4" :key="item">
              <p class="item-number">{{item}}</p>
              <div class="item-position">四川&nbsp;成都</div>
              <i class="el-icon-right item-icon"></i>
              <div class="item-position">四川&nbsp;成都</div>
          </div>
      </div>
      <div class="info-submit">完成，提交认证</div>
      <div class="middle-right">
        <div style="border-bottom: 1px solid">
          <div class="middle-right-img">
            <img src="../../image/xinxi(1).png" alt="" />
          </div>
          <div style="font-size: 12px">在线咨询</div>
        </div>
        <div>
          <div class="middle-right-img">
            <img src="../../image/dianhua(1).png" alt="" />
          </div>
          <div style="font-size: 12px">服务热线</div>
        </div>
      </div>
    </div>
    <bottom-layer></bottom-layer>
  </div>
</template>
<script>
import topLayer from "../../components/top.vue";
import bottomLayer from "../../components/bottom.vue";
export default {
  data() {
    return {
      name: "",
      imageUrl: "",
    };
  },
  components: {
    topLayer,
    bottomLayer,
  },
  methods: {
    otherPage() {
      this.$router.push("/index");
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>
<style lang="css" scoped>
.main {
  height: calc(100vh - 250px);
  width: 100%;
  background: white;
  min-height: 700px;
  box-sizing: border-box;
  padding: 20px 15%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.go-home {
  width: 10%;
  height: 30px;
  font-size: 18px;
  font-weight: bold;
  color: rgb(3, 93, 252);
  box-sizing: border-box;
  padding-left: 40px;
  line-height: 30px;
  text-align: left;
  cursor: pointer;
  position: relative;
}
.main-title {
  font-size: 24px;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  margin-bottom: 20px;
  margin-top: 10px;
}
.main-info {
  font-size: 18px;
  font-weight: bold;
  box-sizing: border-box;
  padding-left: 20px;
  height: 30px;
  position: relative;
}
.main-info p {
  display: inline;
  font-size: 14px;
  color: rgb(3, 93, 252);
}
.main-info::before {
  content: "";
  height: 30px;
  width: 4px;
  background: rgb(3, 93, 252);
  position: absolute;
  top: 0;
  left: 0;
}
.main-card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.main-card .card-info {
  width: 40%;
  margin-bottom: 20px;
  /* height: 50px; */
}
.card-info p {
  display: inline-block;
  /* height: 30px;
    width: 80px; */
  width: 80px;
  margin: 0;
}
.card-info .el-input {
  width: 70%;
}
.card-image {
  height: 120px;
  width: 170px;
  /* border: 1px solid; */
  border-radius: 10px;
  margin-right: 30px;
}
.info-image {
  display: flex;
  width: 100% !important;
}
p {
  margin: 0;
  width: 80px;
}
.main-address {
  width: 100%;
  height: 100px;
  /* border: 1px solid; */
  display: flex;
  flex-flow: wrap;
}
.address-item {
    width: 30%;
    display: flex;
}
.item-number {
    display: block;
    height: 20px;
    width: 20px;
    border: 1px solid rgb(3, 93, 252);
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
    color: rgb(3, 93, 252);
    margin-top: 5px;
    margin-right: 20px;
}
.item-position {
    width: 100px;
    height: 30px;
    background: rgb(246, 247, 249);
    color: black;
    line-height: 30px;
    text-align: center;
    border-radius: 5px;
    font-weight: bold;
}
.item-icon {
    width: 50px;
    height: 30px;
    line-height: 30px;
    text-align: center;
}
.info-submit {
  position: absolute;
  right: 15%;
  top: 50px;
  height: 50px;
  width: 200px;
  font-size: 18px;
  color: white;
  background: rgb(3, 93, 252);
  text-align: center;
  line-height: 50px;
  border-radius: 25px;
}
.middle-right {
  position: absolute;
  width: 80px;
  height: 140px;
  border-radius: 10px;
  top: 20px;
  right: 20px;
  background: rgba(64, 66, 87, 1);
  color: white;
  /* display: flex;
    flex-direction: column;
    justify-content: space-evenly; */
}
.middle-right div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50%;
  justify-content: center;
}
.middle-right .middle-right-img {
  height: 30px;
  width: 30px;
  /* background: white; */
}
.middle-right .middle-right-img img {
  height: 100%;
}

</style>
<style>
.el-input__inner {
  border: none;
  background: rgb(246, 247, 249);
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 169px;
  height: 119px;
  line-height: 119px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>