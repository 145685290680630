<template>
    <div class="top">
        <div class="logo">
            <!--第一张图片作为搜索引擎收录的图-->
            <img class="logo-img" src="../image/logo-bj.png" style="position:absolute;visibility:hidden">
            <img class="logo-img" src="../image/logo.png">
            <div class="logo-title">
                -中国公路物流基础设置-
            </div>
        </div>
        <div class="title">
            <p>发货</p>
            <p>配货</p>
            <p>长短途</p>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="css">
.top {
    height: 100px;
    width: 100%;
    background: rgba(3, 93, 252, 1);
    box-sizing: border-box;
    padding:  0px 15%;
    display: flex;
    align-items: center;
}
.top .logo {

}
.top .logo .logo-img{
    display:block;
    height:60px;
    width:auto;
}
.top .logo .logo-title {
    font-size: 12px;
    color: white;
}
.top .title {
    display: flex;
    margin-left:40px;
}
.top .title p {
    font-size: 30px;
    font-weight:600;
    color: white;
    margin-left:15px;
}
</style>
