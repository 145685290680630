<template>
    <div class="bottom">
        <div class="bottom-left">
            <div class="bottom-left-logo">
                <div class="bottom-left-logo-img"></div>
                <div class="bottom-left-title">
                    齐心帮
                </div>
            </div>
            <div>公司地址：四川省成都市新都区三河街道蓉香路160号缇香荟郡四栋1947-1951室</div>
            <div>联系电话（传真）：15388162979（赵先生）</div>
        </div>
        <div class="bottom-center">
            <div style="height:50px"></div>
            <div>蜀ICP备2022026616号-1</div>
            <div></div>
        </div>
        <div class="bottom-right" :class="this.active ? 'is-home' : ''">
            <div class="bottom-right-driver">
                <div class="bottom-right-driver-logo">
                    <img src="../image/driver-mp-code.jpg">
                </div>
                <div class="driver-title">
                    司机端小程序二维码
                </div>
            </div>
            <div class="bottom-right-driver">
                <div class="bottom-right-driver-logo">
                    <img src="../image/shipper-mp-code.jpg">
                </div>
                <div class="driver-title">
                    货主端小程序二维码
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        active: {
            type: Boolean,
            require: false
        }
    },
}
</script>
<style lang="css">
.bottom {
    height: 150px;
    width: 100%;
    background: rgba(64, 66, 87, 1);
    padding: 10px 15%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 14px;
    text-align: left;
}
.bottom .bottom-left,
.bottom .bottom-center,
.bottom .bottom-right{
    height: 100%;
    display: flex;
}
.bottom .bottom-left {
    width: 40%;
    justify-content: space-around;
    flex-direction: column;
}
.bottom .bottom-center {
    width: 20%;
    flex-direction: column;
    justify-content: space-around;
}

.bottom-left .bottom-left-logo {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.bottom-left .bottom-left-logo-img {
    height: 90%;
    width: 90px;
    /* background-color: white; */
    background: url(../image/logo.png) no-repeat;
    background-size: 100% 100%;
}
.bottom-left .bottom-left-logo .bottom-left-title {
    color: white;
    font-size: 24px;
    font-weight:600;
    margin-left: 20px;
}

.bottom-right {
    width: 30%;
    box-sizing: border-box;
    display:flex;
    flex-direction:row-reverse;
}
.bottom-right *{
    box-sizing:border-box;
}
.bottom-right-driver{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.bottom-right-driver+.bottom-right-driver{
    margin-right:20px;
}
.bottom-right-driver-logo{
    height: 80px;
    width: 80px;
}
.bottom-right-driver-logo img{
    height: 80px;
    width: 80px;
}
.bottom-right-driver .driver-title {
    font-size: 12px;
    display: block;
    text-align: center;
    margin-top:10px;
    white-space:nowrap;
}
.bottom-right.is-home .bottom-right-driver{
    position:relative;
    bottom:25px;
}
.bottom-right.is-home .bottom-right-driver-logo{
    height:150px;
    width:150px;
    padding:15px 15px 15px 15px;
    background:rgba(64, 66, 87, 1);
    border-radius:3px;
}
.bottom-right.is-home .bottom-right-driver-logo img{
    height:120px;
    width:120px;
}
.bottom-right.is-home .bottom-right-driver .driver-title {
    background:#fff;
    padding:3px 10px;
    border-radius:20px;
    color:#330033;
    margin-top:0;
}
</style>
